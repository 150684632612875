<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="16" class="bg-purple-title">出库单基本信息</el-col>
    </el-row>
    <el-form label-width="160px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="出库单号:" :class="$i18n.locale">
              {{ DetailsDatas.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号:" :class="$i18n.locale">
              {{ DetailsDatas.orderId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库类型:">{{ getOutboundTypeLabel(DetailsDatas.outboundType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundWH')+':'" :class="$i18n.locale">
              {{ DetailsDatas.outboundWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应出库数量:">{{ DetailsDatas.expectPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实出库数量:">{{ DetailsDatas.actualPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实出库重量:">{{ DetailsDatas.actualWeight }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实出库体积:">{{ DetailsDatas.actualVolume }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库超时时间:">{{ DetailsDatas.outboundOutTime }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库时间:">{{ DetailsDatas.outboundTime }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态:" :class="$i18n.locale">
              {{ getShipmentStatus(DetailsDatas.outboundStatus) }}</el-form-item>
          </el-col>
          <el-col v-show="DetailsDatas.outboundType == 'transfer'" :span="8">
            <el-form-item label="目的仓库:">{{ DetailsDatas.destinationWarehouseName }}</el-form-item>
          </el-col>
        </el-row>
        <!--  -->
        <el-row v-show="DetailsDatas.outboundType !== 'destroy'" class="grid-content bg-purple-dark">
          <el-col :span="12" class="bg-purple-title">收件信息</el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="收件人:" :class="$i18n.locale">
              {{ addressAear.name }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话:" :class="$i18n.locale">
              {{ addressAear.telephone }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编:">{{ addressAear.postCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Email:"><span style="word-wrap: break-word;">{{ addressAear.mail }}</span></el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收件地址:">{{ addressAear.houseNumber }} {{ addressAear.address }} {{ addressAear.city }} {{ addressAear.state }} {{ addressAear.country }} </el-form-item>
          </el-col>
        </el-row>
        <el-row class="grid-content bg-purple-dark">
          <el-col :span="12" class="bg-purple-title">出库明细
          </el-col>
        </el-row>
        <el-table
          ref="shipmentDetailTable"
          class="mb-3"
          size="mini"
          :data="shipmentDetailTable"
          :header-cell-style="{background:'#fafafa'}"
          max-height="550px"
        >
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="SKU"
            width="270"
            align="center"
            sortable
            :sort-by="['sku_no_size', 'sku_size_sort']"
          >
            <template
              slot-scope="scope"
            >{{ scope.row.sku_no_size ? `${scope.row.sku_no_size}-${scope.row.sku_size}` : scope.row.sku }}</template>
          </el-table-column>
          <el-table-column prop="platSku" label="PlatSKU" align="center" sortable />
          <el-table-column prop="fnsku" label="FNSKU" align="center" sortable />
          <el-table-column prop="upc" label="UPC" align="center" sortable />
          <el-table-column
            prop="style"
            :label="$t('page.Specifications')"
            align="center"
            :sortable="true"
            :sort-by="['style', 'color', 'sizeSort']"
          >
            <template slot-scope="scope">{{ scope.row.style }} / {{ scope.row.color }} / {{ scope.row.size }}</template>
          </el-table-column>
          <el-table-column prop="expectPairs" label="应出库数量" align="center" sortable />
          <el-table-column prop="actualPairs" label="实出库数量" align="center" sortable />
          <el-table-column prop="actualWeight" label="实出库重量(KG)" align="center" sortable />
          <el-table-column prop="actualVolume" label="实出库体积(CBM)" align="center" sortable />
        </el-table>
      </div>
    </el-form>
    <go-back />
  </div>
</template>

<script>
import { findTaskId } from '@/api/inventory-result'

import { getFindOutboundDetail, getFindOutboundAddress } from '@/api/stock-out'
import { Mixin } from '@/mixin/mixin.js'
import GoBack from '@/components/GoBack'

export default {
  components: {
    GoBack
  },
  mixins: [Mixin],
  data() {
    return {
      DetailsDatas: {},
      shipmentDetailTable: [],
      addressAear: {}
    }
  },
  mounted() {
    const { row } = this.$route.query
    this.DetailsDatas = row && JSON.parse(row) || {}
    console.log('%c 🥗  this.DetailsDatas: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.DetailsDatas)
    if (!Object.keys(this.DetailsDatas).length && !this.$route.query.abnormalType) {
      return
    }
    console.log(this.$route.query.abnormalType)
    if (this.$route.query.abnormalType === 'OUT') {
      this._findTaskId(this.$route.query)
    } else {
      this._findCollectDetail(this.DetailsDatas)
    }
  },
  methods: {

    // 获取详情数据
    async _findCollectDetail(data) {
      const { outboundCode, tableSuffix } = data
      const params = { outboundCode, tableSuffix }
      const { datas } = await getFindOutboundDetail(params)
      const results = await getFindOutboundAddress(params)

      this.addressAear = results && results.datas || {}
      this.shipmentDetailTable = datas || []
    },
    // 获取详情数据
    async _findTaskId(data) {
      const { taskId, abnormalType, handleCode } = data
      const params = { taskId, abnormalType, handleCode }
      const { datas } = await findTaskId(params)
      this.DetailsDatas = datas && datas.outbound || {}
      this.addressAear = datas && datas.outboundAddress || {}
      this.shipmentDetailTable = datas && datas.outboundDetailList || []
    },

    getShipmentStatus(status) {
      switch (status + '') {
        case '0':
          return '已生效'
        case '1':
          return '待出库'
        case '2':
          return '已出库'
        case '3':
          return '缺量出库'
        case '4':
          return '终止出库'
        default:
          return ''
      }
    },
    getOutboundType(status) {
      switch (status + '') {
        case 'sell':
          return '销售出库'
        case 'transfer':
          return '调拨出库'
        case 'destroy':
          return '销毁出库'
        default:
          return ''
      }
    }

  }
}
</script>
<style lang="scss" scope>

</style>
